import "./section.scss";

function Section({ css, children, start = true, end = true }) {
  return (
    <section className={`section ${css}`}>
      {start && (
        <svg
          className="section__wave transition duration-300 ease-in-out delay-150"
          width="100%"
          height="100%"
          id="svg"
          viewBox="0 0 1440 390">
          <path
            d="M 0,400 L 0,0 C 70.08656818962555,31.90656131913432 140.1731363792511,63.81312263826864 206,94 C 271.8268636207489,124.18687736173136 333.3940226726211,152.65407076605976 395,174 C 456.6059773273789,195.34592923394024 518.2507729302645,209.57059429749228 597,216 C 675.7492270697355,222.42940570250772 771.6028856063207,221.06355204397113 849,206 C 926.3971143936793,190.93644795602887 985.3376846444523,162.17519752662318 1052,166 C 1118.6623153555477,169.82480247337682 1193.0463758158708,206.23565784953627 1259,184 C 1324.9536241841292,161.76434215046373 1382.4768120920646,80.88217107523187 1440,0 L 1440,400 L 0,400 Z"
            stroke="none"
            stroke-width="0"
            fill-opacity="1"
            class="transition-all duration-300 ease-in-out delay-150 path-0"></path>
        </svg>
      )}
      <div className="section__content">{children}</div>
      {end && (
        <svg
          width="100%"
          height="100%"
          id="svg"
          viewBox="0 0 1440 400"
          xmlns="http://www.w3.org/2000/svg"
          className="section__wave transition duration-300 ease-in-out delay-150">
          <path
            d="M 0,400 L 0,0 C 109.7129186602871,13.559808612440193 219.4258373205742,27.119617224880386 329,80 C 438.5741626794258,132.8803827751196 548.0095693779905,225.08133971291866 620,223 C 691.9904306220095,220.91866028708134 726.5358851674641,124.55502392344499 822,121 C 917.4641148325359,117.44497607655501 1073.8468899521529,206.69856459330143 1187,202 C 1300.1531100478471,197.30143540669857 1370.0765550239234,98.65071770334929 1440,0 L 1440,400 L 0,400 Z"
            stroke="none"
            stroke-width="0"
            fill-opacity="1"
            class="transition-all duration-300 ease-in-out delay-150 path-1"
            transform="rotate(-180 720 200)"></path>
        </svg>
      )}
    </section>
  );
}

export default Section;
